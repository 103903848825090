import React from 'react'
import { useDevice } from 'device'
import { track } from 'analytics'
import { cx } from 'helpers'
import links, { getLinkWithParams } from 'links'

import { Text } from 'components/dataDisplay'
import { Button, buttonMessages } from 'components/inputs'
import HeaderBannerContainer from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import messages from './messages'


type WorldOfFragranceBannerProps = {
  position?: number
}

const WorldOfFragranceBanner: React.FC<WorldOfFragranceBannerProps> = (props) => {
  const { position } = props
  const { isMobile } = useDevice()

  const link = getLinkWithParams(links.promoHub.landing, { slug: 'world-of-fragrance' })
  const wrapperClassName = cx('bg-dark-red', isMobile ? 'w-full justify-between px-16' : 'gap-16')

  return (
    <HeaderBannerContainer
      className={wrapperClassName}
      to={link}
      height={isMobile ? 64 : 56}
      onClick={() => {
        track('Banner click', {
          placement: 'Header',
          content: 'Promo hub',
          contentDetails: 'World of Fragrance',
          action: 'link',
          link,
          position,
        })
      }}
      data-testid="worldOfFraganceTopBarBanner"
    >
      <Text className="mt-4" message={messages.text} style="h8" html />
      <Button className="px-16" size={38} noPadding title={buttonMessages.shopNow} />
    </HeaderBannerContainer>
  )
}

export default WorldOfFragranceBanner
