import React, { useEffect } from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { track } from 'analytics'
import { preloadModal } from 'modals'
import { openCartModal, useCart } from 'modules/cart'

import { Icon } from 'components/dataDisplay'

import messages from './messages'


type CartProps = {
  className?: string
}

const Cart: React.FunctionComponent<CartProps> = (props) => {
  const { className } = props
  const { cart, isFetching } = useCart()

  const intl = useIntl()
  const label = intl.formatMessage(messages.label)

  useEffect(() => {
    void preloadModal('cartModal')
  }, [])

  if (isFetching) {
    return (
      <Icon className={className} name="32/loader" color="white" />
    )
  }

  const handleClick = () => {
    track('Cart icon click')
    openCartModal({
      flow: 'Cart icon',
    })
  }

  return (
    <button
      className={cx(className, 'relative cursor-pointer text-white')}
      type="button"
      aria-label={label}
      data-testid="cart"
      onClick={handleClick}
    >
      <Icon name="32/cart" />
      {
        cart?.totalCount > 0 && (
          <span
            className="size-s16 text-eye3 absolute right-0 top-0 flex items-center justify-center rounded-full border-2 border-black bg-white text-black"
            data-testid="cartCount"
          >
            {cart.totalCount}
          </span>
        )
      }
    </button>
  )
}


export default Cart
