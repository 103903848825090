import React, { Children, isValidElement } from 'react'
import { Carousel } from 'components/dataDisplay'


const BannersCarousel: React.FC<{ children: TopBarBanner.CarouselItem[] }> = (props) => {
  const { children } = props

  // return one banner as is
  if (Children.count(children) <= 1) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Carousel
      className="relative overflow-hidden"
      withDots
      dots={{
        className: 'absolute inset-x-0 bottom-0 pointer-events-none',
        dotClassName: 'mb-4',
      }}
      loop
      autoplay
      autoplaySpeed={4000}
    >
      {
        Children.map(children, (child, index) => {
          if (!isValidElement(child)) {
            return child
          }

          return (
            React.cloneElement(child, {
              className: 'pb-12',
              position: index + 1,
            })
          )
        })
      }
    </Carousel>
  )
}


export default BannersCarousel
