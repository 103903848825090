import { useMemo } from 'react'
import links from 'links'
import { constants } from 'helpers'
import { useDevice } from 'device'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import messages from './messages'


type UseHolidaySeasonBannerProps = {
  discount?: number
}

type BannerConfig = {
  link: string
  textStyle: 'h8' | 'h9'
  textMessage: Intl.Message
}

const useHolidaySeasonBanner = ({ discount }: UseHolidaySeasonBannerProps) => {
  const { isMobile } = useDevice()

  const isValentinesHeaderBannerGgFocusedEnabled = useFeatureIsOn(constants.features.valentinesHeaderBannerGgFocused)
  const isValentinesHeaderBannerUpTo60OffEnabled = useFeatureIsOn(constants.features.valentinesHeaderBannerUpTo60Off)
  const isValentinesHeaderBannerGiftSubPushEnabled = useFeatureIsOn(constants.features.valentinesHeaderBannerGiftSubPush)
  const isGiftSubscriptionEntrypointsEnabled = useFeatureIsOn(constants.features.giftSubscriptionEntrypoints)

  const isValentinesHeaderBannerEnabled =
    isValentinesHeaderBannerGgFocusedEnabled
    || isValentinesHeaderBannerUpTo60OffEnabled
    || isValentinesHeaderBannerGiftSubPushEnabled

  const textClassName = isMobile && isValentinesHeaderBannerEnabled ? 'text-left' : 'text-center'

  const { link, textStyle, textMessage } = useMemo<BannerConfig>(() => {
    // Valentine's Day: Gift Subscription push
    if (isValentinesHeaderBannerGiftSubPushEnabled && isGiftSubscriptionEntrypointsEnabled) {
      return {
        link: links.giftSubscription,
        textStyle: 'h8',
        textMessage: messages.valentinesGiftSubPush,
      }
    }

    // Valentine's Day: Up to 60% off
    if (isValentinesHeaderBannerUpTo60OffEnabled) {
      return {
        link: links.shop.root,
        textStyle: isMobile ? 'h9' : 'h8',
        textMessage: messages.valentinesUpTo60Off,
      }
    }

    // Valentine's Day: GG focused
    if (isValentinesHeaderBannerGgFocusedEnabled) {
      return {
        link: links.gifts, // or whatever fits best for GG-focused
        textStyle: 'h8',
        textMessage: messages.valentinesGGFocused,
      }
    }

    // Holiday discount
    if (discount) {
      return {
        link: links.shop.holiday,
        textStyle: 'h8',
        textMessage: {
          ...messages.title,
          values: { discount },
        },
      }
    }

    // Default banner
    return {
      link: links.gifts,
      textStyle: 'h8',
      textMessage: messages.defaultTitle,
    }
  }, [
    isMobile,
    discount,
    isValentinesHeaderBannerGgFocusedEnabled,
    isValentinesHeaderBannerUpTo60OffEnabled,
    isValentinesHeaderBannerGiftSubPushEnabled,
    isGiftSubscriptionEntrypointsEnabled,
  ])

  return {
    link,
    textStyle,
    textClassName,
    textMessage,
    withBgImage: !isValentinesHeaderBannerEnabled,
  }
}

export default useHolidaySeasonBanner
