import React, { useMemo } from 'react'
import links from 'links'
import { usePathname } from 'router'
import { constants } from 'helpers'
import { useExpiration, useFt } from 'hooks'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import { useUser } from 'modules/user'
import { usePrivateSale } from 'modules/products'
import { useCandleSubscription, useDriftSubscription, useSubscription } from 'modules/subscription'
import { useLimitedDropSale } from 'modules/limitedDrops'

// Banner components
import SubscribeHeaderBanner from 'compositions/banners/SubscribeHeaderBanner/SubscribeHeaderBanner'
import CardUpdateBanner from '../components/CardUpdateBanner/CardUpdateBanner'
import UndeliverableBanner from '../components/UndeliverableBanner/UndeliverableBanner'
import TossInBanner from '../components/TossInBanner/TossInBanner'
import PrivateSaleBanner from '../components/PrivateSaleBanner/PrivateSaleBanner'
import UnskipBanner from '../components/UnskipBanner/UnskipBanner'
import CandleSubscriptionBanner from '../components/CandleSubscriptionBanner/CandleSubscriptionBanner'
import HolidaySeasonBanner from '../components/HolidaySeasonBanner/HolidaySeasonBanner'
import ResubscribeBanner from '../components/ResubscribeBanner/ResubscribeBanner'
import DriftSubscriptionTextBanner from '../components/DriftSubscriptionTextBanner/DriftSubscriptionTextBanner'
import BfcmBanner from '../components/BfcmBanner/BfcmBanner'
import GsPostCutoffBanner from '../components/GsPostCutoffBanner/GsPostCutoffBanner'
import GiftSubscriptionBanner from '../components/GiftSubscriptionBanner/GiftSubscriptionBanner'
import GwpTextBanner from '../components/GwpTextBanner/GwpTextBanner'
import MansGroomingBanner from '../components/MansGroomingBanner/MansGroomingBanner'
import TheSummerEditBanner from '../components/TheSummerEditBanner/TheSummerEditBanner'
import VipSubscriberSaleBanner from '../components/VipSubscriberSaleBanner/VipSubscriberSaleBanner'
import CandleSaleBanner from '../components/CandleSaleBanner/CandleSaleBanner'
import LimitedDropBanner from '../components/LimitedDropBanner/LimitedDropBanner'
import WorldOfFragranceBanner from '../components/WorldOfFragranceBanner/WorldOfFragranceBanner'


const normalizePagePaths = (pagePaths: string[]) => {
  return pagePaths
    .map((link) => link.replace('(.*)', '').replace(':slug', ''))
    .join('|')
}

const getFirstValidChecker = <T, >(checkers: Array<() => T>): T => {
  let validCheckerResult = null

  for (const checker of checkers) {
    const bannerElement = typeof checker === 'function' ? checker() : null

    if (React.isValidElement(bannerElement)) {
      validCheckerResult = bannerElement
      break
    }
  }

  return validCheckerResult
}

const productPagePaths = normalizePagePaths([
  links.productGeneral,
  links.productCandle,
  links.productExtra,
  links.productMakeup,
  links.productPerfume,
  links.productPerfumeLanding,
  links.productPersonalCare,
  links.productSkincare,
  links.productWellness,
  links.productDiffuser,
  links.productAccessory,
])

const vipSalePagePaths = normalizePagePaths([
  links.shop.travelSize.root,
  links.shop.samples.root,
  links.shop.scentbirdCases,
])

const limitedDropPagePaths = normalizePagePaths([
  links.currentLimitedDrop,
  links.limitedDrop,
  links.limitedDropProduct,
])

const productPagePathRegExp = new RegExp(`^(${productPagePaths})`)
const vipSalePagePathRegExp = new RegExp(`^(${vipSalePagePaths})`)
const limitedDropPagePathRegExp = new RegExp(`^(${limitedDropPagePaths})`)

/**
 * TODO:
 * 1) Remove all redundant banners (make sure that Strapi supports their visual format)
 * 2) Unify the names and locations of all composite top bar components
 * 3) Resolve gift hub banners condition hell
 * Added on 24–03–2025 by algeas
 */
const useTopBarBanner = () => {
  const pathname = usePathname()

  const { isLoggedIn, isFetching: isUserFetching } = useUser()
  const { subscription, isFetching: isSubscriptionFetching } = useSubscription()
  const { privateSale, isFetching: isPrivateSaleFetching } = usePrivateSale()
  const { candleSubscription, isFetching: isCandleSubscriptionFetching } = useCandleSubscription()
  const { driftSubscription } = useDriftSubscription()

  const { data: limitedDropData, isFetching: isLimitedDropFetching } = useLimitedDropSale()

  const isGiftHubEnabled = useFt(constants.features.giftHub)
  const isHolidayMothersDayEnabled = useFt(constants.features.holidayMothersDay)
  const isHolidayFathersDayEnabled = useFt(constants.features.holidayFathersDay)
  const isGiftHubHeaderBannerEnabled = useFeatureIsOn(constants.features.giftHubHeaderBanner)
  const isHeaderBannerVisibleOnGiftHubEnabled = useFeatureIsOn(constants.features.headerBannerVisibleOnGiftHub)

  const holidayBannerDiscountValue = useFeatureValue(constants.features.holidayBanner, 0)

  const isDriftSubscriptionEnabled = useFt(constants.features.driftSubscription)
  const isDriftSubscriptionLaunchPromoEnabled = useFt(constants.features.driftSubscriptionLaunchPromo)

  const isBfcmNotificationEnabled = useFeatureIsOn(constants.features.bfcmNotification)
  const isGiftSubscriptionNotificationEnabled = useFeatureIsOn(constants.features.giftSubscriptionNotification)

  const isGwpTopBarVisible = useFeatureIsOn(constants.features.gwpTopBar)
  const isMansGroomingTopBarVisible = useFeatureIsOn(constants.features.mansGroomingTopBar)
  const isTheSummerEditEnabled = useFeatureIsOn(constants.features.theSummerEdit)
  const isVipSubscriberSaleEnabled = useFeatureIsOn(constants.features.vipSubscriberSale)
  const isCandleSaleEnabled = useFeatureIsOn(constants.features.candleSale)
  const isGsPostCutoffEntryPointsEnabled = useFeatureIsOn(constants.features.gsPostCutoffEntryPoints)
  const isGiftSubscriptionEntrypointsEnabled = useFeatureIsOn(constants.features.giftSubscriptionEntrypoints)
  const isWorldOfFragranceTopBarVisible = useFeatureIsOn(constants.features.worldOfFragranceTopBar)

  const isPrivateSaleExpired = useExpiration({ timeLeft: privateSale?.timeLeft })

  const isFetching = isUserFetching || isSubscriptionFetching || isPrivateSaleFetching || isCandleSubscriptionFetching || isLimitedDropFetching

  const banners = useMemo(() => {

    // If still fetching data, return empty
    if (isFetching) {
      return []
    }

    // SINGLE BANNER BLOCK
    const getUnskipBanner = () => {
      const isVisible = (
        isLoggedIn
        && subscription?.isOnHold
        && Boolean(subscription?.activeHold)
      )
      return isVisible && (
        <UnskipBanner key="unskip" />
      )
    }

    const getCardUpdateBanner = () => {
      const isVisible = subscription?.isUnpaid
      return isVisible && (
        <CardUpdateBanner key="cardUpdate" />
      )
    }

    const getUndeliverableBanner = () => {
      const isVisible = subscription?.isUndeliverable
      return isVisible && (
        <UndeliverableBanner key="undeliverable" />
      )
    }

    const getResubscribeBanner = () => {
      const isVisible = Boolean(subscription?.isCancelled)
      return isVisible && (
        <ResubscribeBanner key="resubscribe" />
      )
    }

    const getSubscribeBanner = () => {
      const isVisible = (
        subscription?.hasNeverSubscribed
        && pathname !== links.subscription.main
        && !productPagePathRegExp.test(pathname)
      )
      return isVisible && (
        <SubscribeHeaderBanner key="subscribeSingle" />
      )
    }

    const getDriftSubscriptionTextBannerPriority = () => {
      const isVisible = (
        isDriftSubscriptionEnabled
        && driftSubscription?.isEnabled
        && !driftSubscription?.isSelected
        && pathname !== links.driftSubscription
        && isDriftSubscriptionLaunchPromoEnabled
      )
      return isVisible && (
        <DriftSubscriptionTextBanner key="driftPrioritySingle" />
      )
    }

    const getHolidaySeasonBanner = () => {
      const isBfcmBannerVisible = (
        isBfcmNotificationEnabled
        && pathname === links.shop.holiday
      )

      const isGiftSubscriptionBannerVisible = (
        isGiftSubscriptionNotificationEnabled
        && pathname === links.giftSubscription
        && isGiftSubscriptionEntrypointsEnabled
      )

      const isVisible = (
        isGiftHubEnabled
        // This logic is little strange, but we needed to show the holiday banner only on the Gifts page for a limited period of time
        && (pathname !== links.gifts ? isGiftHubHeaderBannerEnabled : isHeaderBannerVisibleOnGiftHubEnabled)
        && !isHolidayMothersDayEnabled
        && !isHolidayFathersDayEnabled
        && !isBfcmBannerVisible
        && !isGiftSubscriptionBannerVisible
      )

      return isVisible && (
        <HolidaySeasonBanner key="holidayHubSingle" />
      )
    }

    const getMansGroomingBanner = () => {
      return isMansGroomingTopBarVisible && (
        <MansGroomingBanner key="mansGroomingSingle" />
      )
    }

    const getSummerEditBanner = () => {
      const isVisible = (
        isTheSummerEditEnabled
        && pathname !== links.shop.theEdit
      )
      return isVisible && (
        <TheSummerEditBanner key="theSummerEditSingle" />
      )
    }

    const getVipSubscriberSaleBanner = () => {
      const isVisible = (
        isVipSubscriberSaleEnabled
        && !vipSalePagePathRegExp.test(pathname)
      )
      return isVisible && (
        <VipSubscriberSaleBanner key="vipSubscriberSaleSingle" />
      )
    }

    const getCandleSaleBanner = () => {
      const isVisible = (
        isCandleSaleEnabled
        && pathname !== links.shop.candlesHomeScents
      )
      return isVisible && (
        <CandleSaleBanner key="candleSaleSingle" />
      )
    }

    const getWorldOfFragranceBanner = () => {
      return isWorldOfFragranceTopBarVisible && (
        <WorldOfFragranceBanner key="worldOfFragranceSingle" />
      )
    }

    const singleBannerCandidates = [
      getUnskipBanner,
      getCardUpdateBanner,
      getUndeliverableBanner,
      getResubscribeBanner,
      getSubscribeBanner,
      getDriftSubscriptionTextBannerPriority,
      getHolidaySeasonBanner,
      getMansGroomingBanner,
      getSummerEditBanner,
      getVipSubscriberSaleBanner,
      getCandleSaleBanner,
      getWorldOfFragranceBanner,
    ]

    const firstSingleBanner = getFirstValidChecker<React.JSX.Element>(singleBannerCandidates)

    if (firstSingleBanner) {
      return [ firstSingleBanner ]
    }

    // MULTI BANNER BLOCK
    const getGwpTextBanner = (position: number) => {
      return isGwpTopBarVisible && (
        <GwpTextBanner key="gwpBanner" position={position} />
      )
    }

    const getBfcmBanner = (position: number) => {
      const isVisible = (
        isBfcmNotificationEnabled
        && pathname === links.shop.holiday
      )
      return isVisible && (
        <BfcmBanner key="BfcmBanner" position={position} />
      )
    }

    const getGiftSubscriptionBanner = (position: number) => {
      if (isGsPostCutoffEntryPointsEnabled) {
        return <GsPostCutoffBanner key="GiftSubscriptionBanner" />
      }

      const isVisible = (
        isGiftSubscriptionNotificationEnabled
        && pathname === links.giftSubscription
        && isGiftSubscriptionEntrypointsEnabled
      )
      return isVisible && (
        <GiftSubscriptionBanner key="GiftSubscriptionBanner" />
      )
    }

    const getDriftSubscriptionBanner = (position: number) => {
      const isVisible = (
        isDriftSubscriptionEnabled
        && driftSubscription?.isEnabled
        && !driftSubscription?.isSelected
        && pathname !== links.driftSubscription
      )
      return isVisible && (
        <DriftSubscriptionTextBanner key="driftSubscription" position={position} />
      )
    }

    const getLimitedDropBanner = (position: number) => {
      const isVisible = Boolean(
        limitedDropData?.entryPointsUsageMap?.topBar
        && !limitedDropPagePathRegExp.test(pathname)
      )

      return isVisible && (
        <LimitedDropBanner key="limitedDrop" position={position} />
      )
    }

    const getHolidaySeasonBannerDiscount = (position: number) => {
      const isVisible = pathname !== links.shop.holiday && holidayBannerDiscountValue > 0

      return isVisible && (
        <HolidaySeasonBanner key="holidayDiscount" discount={holidayBannerDiscountValue} position={position} />
      )
    }

    const getCandleSubscriptionBanner = (position: number) => {
      const isVisible = (
        candleSubscription?.isEnabled
        && !candleSubscription?.isSelected
        && pathname !== links.candleSubscription
        && pathname !== links.featuredCandle
      )
      return isVisible && (
        <CandleSubscriptionBanner key="candleSubscription" position={position} />
      )
    }

    const getTossInBanner = (position: number) => {
      const isVisible = (
        isLoggedIn
        && pathname !== links.tossIn
        && subscription?.canApplyTossInOffer
      )
      return isVisible && (
        <TossInBanner key="tossIn" position={position} />
      )
    }

    const getPrivateSaleBanner = (position: number) => {
      const isVisible = (
        isLoggedIn
        && pathname !== links.privateSale
        && Boolean(privateSale)
        && !isPrivateSaleExpired
      )
      return isVisible && (
        <PrivateSaleBanner key="privateSale" position={position} />
      )
    }

    const multiBanners = [
      getGwpTextBanner,
      getBfcmBanner,
      getGiftSubscriptionBanner,
      getDriftSubscriptionBanner,
      getLimitedDropBanner,
      getHolidaySeasonBannerDiscount,
      getCandleSubscriptionBanner,
      getTossInBanner,
      getPrivateSaleBanner,
    ]
      .map((checker, index) => checker(index))
      .filter(Boolean)

    return multiBanners
  }, [
    pathname,
    subscription,
    privateSale,
    candleSubscription,
    driftSubscription,
    limitedDropData,
    isLoggedIn,
    isFetching,
    isPrivateSaleExpired,
    isDriftSubscriptionEnabled,
    isDriftSubscriptionLaunchPromoEnabled,
    isGiftHubEnabled,
    isHolidayMothersDayEnabled,
    isHolidayFathersDayEnabled,
    isBfcmNotificationEnabled,
    isGiftSubscriptionNotificationEnabled,
    isGiftHubHeaderBannerEnabled,
    isHeaderBannerVisibleOnGiftHubEnabled,
    isGwpTopBarVisible,
    isMansGroomingTopBarVisible,
    isTheSummerEditEnabled,
    isVipSubscriberSaleEnabled,
    isCandleSaleEnabled,
    isGsPostCutoffEntryPointsEnabled,
    isGiftSubscriptionEntrypointsEnabled,
    isWorldOfFragranceTopBarVisible,
    holidayBannerDiscountValue,
  ])

  return banners
}


export default useTopBarBanner
