import React from 'react'

import BannersCarousel from './components/BannersCarousel/BannersCarousel'

import useTopBarBanner from './util/useTopBarBanner'


const TopBarBanner: React.FunctionComponent = () => {
  const banners = useTopBarBanner()

  if (!banners.length) {
    return null
  }

  if (banners.length === 1) {
    return banners[0]
  }

  return (
    <BannersCarousel>
      {banners}
    </BannersCarousel>
  )
}


export default TopBarBanner
