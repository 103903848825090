import React from 'react'
import links from 'links'
import { track } from 'analytics'
import { useLocationState } from 'router'
import { useAb } from 'hooks'
import { constants, cx } from 'helpers'
import { useDevice } from 'device'
import { useUser } from 'modules/user'

import { Text } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { buttonMessages } from 'components/inputs'


const UserNavWrapper: React.FunctionComponent<{ children: React.ReactElement, withRedirect: boolean }> = ({ children, withRedirect }) => {
  const { isMobile } = useDevice()
  const { user, isLoggedIn, isFetching } = useUser()
  const { currentUrl } = useLocationState()

  const isTryNowLinkAbEnabled = useAb(constants.abTests.headerTryNowLink) === 'b'
  const isLead = !isLoggedIn || user?.analyticsMetadata?.subscriptionStatus === 'LEAD'

  if (isFetching) {
    return null
  }

  const tryNowLinkNode = (
    <Href
      className={cx(!isMobile && 'rounded-[35rem] bg-dark-red px-24 py-8')}
      to={isLoggedIn ? links.subscribe : `${links.register}?redirect=${links.subscribe}`}
      onClick={() => {
        track('Try now button click', {
          placement: 'header',
        })
      }}
      data-testid="tryNowButton"
    >
      <Text message={buttonMessages.tryNow} style="t2" />
    </Href>
  )

  const logInLinkNode = (
    <Href
      to={`${links.login}${withRedirect ? `?redirect=${encodeURIComponent(currentUrl)}` : ''}`}
      onClick={() => {
        track('Login button click')
      }}
      data-testid="loginButton"
    >
      <Text message={buttonMessages.login} style="t2" />
    </Href>
  )

  if (isTryNowLinkAbEnabled) {
    if (isMobile) {
      return isLoggedIn ? children : tryNowLinkNode
    }
    else {
      return (
        <div className="flex items-center gap-12">
          {
            isLoggedIn ? (
              children
            ) : (
              logInLinkNode
            )
          }
          {
            isLead && (
              tryNowLinkNode
            )
          }
        </div>
      )
    }
  }

  if (!isLoggedIn) {
    return logInLinkNode
  }

  return children
}


export default UserNavWrapper
